import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Confetti from 'react-dom-confetti'

import Layout from '../components/Layout'
import Content, { HTMLContent } from '../components/Content'
import PinkCard from '../components/PinkCard'


export const AboutPageTemplate = ({ title, content, contentComponent, foldTitle, foldBody, foldImg }) => {
  const PageContent = contentComponent || Content
  
  const [confetti, setConfetti] = useState(false);
  
  return (
    <section className="section section--gradient">
      <div className="container">
        <PinkCard>
          <div className='card-text'>
            <h1 className="intro-title">{foldTitle}</h1>
            <p style={{fontSize: '1.2rem'}}>{foldBody}</p>
          </div>
          <div>
            <div className='card-img' style={{background: `url(${foldImg})`, backgroundSize: 'cover', backgroundPosition: '50%, 50%'}} />
          </div>
        </PinkCard>
        <div className="columns">
          <div className="column is-10 is-offset-1">
            <div className="section">
              <PageContent className="content markdown-content" content={content} />
            </div>
          </div>
        </div>
        <PinkCard>
          <div style={{textAlign: 'center', width: '100%'}}>
            <h1 className='intro-title' style={{color: '#FFF'}}>Check out Sarah's Etsy Shop!</h1>
            <p style={{fontSize: '1.2rem', marginBottom: 18}}>All sorts of crafts and spunky items for you, or the perfect gift for someone special!</p>
            <a className='unbutton' style={{cursor: 'pointer'}} href='https://www.etsy.com/shop/SarahGrandDesigns' target="_blank" rel="noopener noreferrer">
              <div style={{width: '100%', transform: 'translateX(50%)'}}>
                <Confetti 
                  active={confetti} 
                  config={{
                    angle: 45,
                    spread: 360, 
                    width: '10px',
                    height: '10px',
                    elementCount: 500,
                    stagger: 10,
                    dragFriction: 0.22,
                    colors: ['#ffffff', '#ebcae1', '#ee5da1']
                  }} />
              </div>
              <button onMouseEnter={() => setConfetti(true)} onMouseLeave={() => setConfetti(false)} style={{border: 'none', background: '#FFF', cursor: 'pointer'}}>
                Go to the shop
              </button>
            </a>
          </div>
        </PinkCard>
      </div>
    </section>
  )
}

AboutPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
}

const AboutPage = ({ data }) => {
  const { markdownRemark: post } = data
  console.log(post)
  return (
    <Layout color="yellow">
      <AboutPageTemplate
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        content={post.html}
        foldTitle={post.frontmatter.foldTitle}
        foldBody={post.frontmatter.foldBody}
        foldImg={post.frontmatter.foldImg}
      />
    </Layout>
  )
}

AboutPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default AboutPage

export const aboutPageQuery = graphql`
  query AboutPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        foldTitle
        foldBody
        foldImg
      }
    }
  }
`
